.button{
    background: #7C2F71;
    width: fit-content;
    // border-radius: 5px;
}


.mail-button{
    background: #737576
    ;
}