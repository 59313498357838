.button-text{
    color: #ffffff;
}


.button-text:hover{
    opacity: 0.6;
    background: #7C2F71;
}

