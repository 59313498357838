.home{
    position: relative;


}

.home:after {
    content:'';
    background: url('../../../../assets/images/orangeback.jpg') no-repeat ;
    position: absolute;
    background-size: auto;
    height: 80%;
    z-index:-1;
    opacity: 0.2; /* Here is your opacity */
}