.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(primary, 3);
}


.features{
	background-color: #d0d3d6;
	border-radius: 25px;
}



